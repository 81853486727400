import React, { useState, useEffect } from 'react';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import './StripeButton.css'; 

import { formatDateAsTh } from "../utils/utilFn";
import { constants } from "../utils/constants";



const AccountSetting = ({ setActiveNavItem}) => {
    const [userName, setUserName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isUsernameUnique, setIsUsernameUnique] = useState(true); 
    const [isUsernameExists, setIsUsernameExists] = useState(false); 
    const [isChangesMade, setIsChangesMade] = useState(false);
    const [originalValues, setOriginalValues] = useState({});
    const [updatedUserAccount, setUpdatedUserAccount] = useState(false);
    const [displayEmailId, setDisplayEmailId] = useState('');
    const [userPlan, setUserPlan] = useState('');
    const [displayLabel, setDisplayLabel] = useState([]);
    const [isTrialPlan, setIsTrialPlan] = useState(false);
    const [trialExpiryDate, setTrialExpiryDate] = useState(false);
    const [userFuturePlan, setUserFuturePlan] = useState({});
    
    
    // useEffect(() => {
    //   // Load the Stripe Buy Button script dynamically
    //   const script = document.createElement('script');
    //   script.src = "https://js.stripe.com/v3/buy-button.js";
    //   script.async = true;
    //   script.onload = () => {
    //     if (window.StripeBuyButton) {
    //       window.StripeBuyButton.mount();
    //     }
    //   };
    //   document.body.appendChild(script);
  
    //   return () => {
    //     // Cleanup script when the component is unmounted
    //     document.body.removeChild(script);
    //   };
    // }, []);
  
    // const handleUpgradeClick = () => {
    //   // Trigger the Stripe Buy Button click programmatically
    //   const stripeButton = document.querySelector('stripe-buy-button');
      
    //   if (stripeButton) {
    //     console.log("clikceee", stripeButton)
    //     if (stripeButton) {
    //       // Create and dispatch a mouse event to simulate a click
    //       const event = new MouseEvent('click', {
    //         view: window,
    //         bubbles: true,
    //         cancelable: true,
    //       });
    //       console.log("event",  event)
    //       stripeButton.dispatchEvent(event);
    //     }
    //   }
    // };

    const handleFirstNameChange = async(event) => {
        const value = event.target.value;
        setFirstName(value)
        
    }
    const handleLastNameChange = async(event) => {
        const value = event.target.value;
        setLastName(value)
        
    }

    const handleUsernameChange = async (event) => {
        const value = event.target.value;
        setUserName(value);
        console.log(value,userName);
        try {
            const response = await checkUsernameAvailability(value);
            console.log("response",response)
            if(response !== undefined && response !== null && String(response).trim() !== '') {
              console.log("Truuuu")
              setIsUsernameUnique(false);
            } else {
              console.log("False")
              setIsUsernameUnique(true);
            }
          } catch (error) {
            console.error('Error checking username availability:', error);
            // Handle the error as needed (e.g., show an error message to the user)
          }
      };
      useEffect(() => {
         // Compare current values with original values
         const hasChanges =
           firstName !== originalValues.firstName ||
           lastName !== originalValues.lastName ||
           userName !== originalValues.userName;
     console.log("firstName",firstName,originalValues.firstName )
     console.log("lastName",lastName,originalValues.lastName )
     console.log("userName",userName,originalValues.userName )
         setIsChangesMade(hasChanges);
       }, [firstName, lastName, userName, originalValues]);

   
      const checkUsernameAvailability = async (userName) => {
        // Replace this with your actual server endpoint
        const response = await callAPI(apiUrls.CHECKUNQNAME, {userName:userName}, "GET");
        const data = response.data.id;
        return data;
      };
    useEffect(() => {
       const fetchData = async () => {
         try {
           const userId=localStorage.getItem("userId")
           
              const response = await callAPI(apiUrls.GETACCOUNTDATA, {userId:userId}, "GET");;
              console.log(response);
             if (response.data.name && response.data.name.trim() !== '') {
                const names = response.data.name.split(' ');
                setFirstName(names[0] || ''); // Set first name (or an empty string if not available)
                setLastName(names.slice(1).join(' '));
                setOriginalValues({
                  firstName: names[0] || '',
                  lastName: names.slice(1).join(' '),
                  userName: response.data.userName?response.data.userName:"",
                });
             }
             if(response.data.userName && response.data.userName.trim() !== '') {
                setUserName(response.data.userName)
                setIsUsernameExists(true)

             }
             setDisplayEmailId(response.data.emailId);

         

          
         // setPlans(response.data.plans); 
          const userResponse = await callAPI(apiUrls.GETUSERPLAN, {userId:userId}, "GET");
          const labels = {
            "1016": "Decks",
            "1003": "Characters Used",
            "1006": "Voice audio",
            "1014": "Voice Presenters",
          };
          let paymentResp;
          if(userResponse.data.plan) {
             paymentResp = await callAPI(apiUrls.GETPAYMENTPLANS, {}, "GET");
            const matchingPlan = paymentResp.data.plans.find(plan => plan.pid === userResponse.data.plan.planId);  
            if(matchingPlan) {
            const description = JSON.parse(matchingPlan.description); // Parse the description JSON string
            const matchingResult = {
              title: matchingPlan.title,
              price: description.price,
              priceLabel: description.priceLabel,
            }
            setUserPlan(matchingResult);

            }
            //  if(userResponse.data.plan.title == "Trial Plan") {
            //     setIsTrialPlan(true);
            //  }
             const upid = await JSON.parse(localStorage.getItem("upid"));
             console.log("upidd", upid)
             if (upid && upid.trialPlan && (upid.trialPlan.isTrial === true || upid.trialPlan.istrial === "true")) {
              console.log("vbgt")
              setIsTrialPlan(true);
              setTrialExpiryDate(formatDateAsTh(upid.trialPlan.expiry))
             } 

            //  const displayData = Object.keys(upid.userRestData)
            //  .filter(key => labels[key]) // Only include keys that exist in the labels object
            //  .map(key => {
            //    let actual = upid.userActualData[key] || 0;
            //    let restrict = upid.userRestData[key];
           
            //    // Special case for key '1006': Convert to minutes
            //    if (key === "1006") {
            //       actual = (actual / 60).toFixed(2) ;
            //       restrict = Math.round(restrict / 60) + " mins";
            //    }  else if (key === "1016") {
            //     actual = (upid.userActualData[constants.T_DU_PPT] || 0) + 
            //              (upid.userActualData[constants.T_DU_PDF] || 0);
            //   }
            //    return {
            //      label: labels[key],
            //      actual,
            //      restrict,
            //    };
            //  });
            const orderedKeys = ["1016", "1003", "1006", "1014"]; // Explicit order from labels

            const displayData = orderedKeys.map(key => {
              let actual = upid.userActualData[key] || 0;
              let restrict = upid.userRestData[key];
            
              // Special case for key '1006': Convert to minutes
              if (key === "1006") {
                actual = (actual / 60).toFixed(2);
                restrict = Math.round(restrict / 60) + " mins";
              } else if (key === "1016") {
                actual = (upid.userActualData[constants.T_DU_PPT] || 0) + 
                         (upid.userActualData[constants.T_DU_PDF] || 0);
              }
            
              return {
                label: labels[key], // Ensure it uses the corresponding label
                actual,
                restrict,
              };
            });
            
             setDisplayLabel(displayData);
          } else {
            setUserPlan(false)
          }
          if(userResponse.data.futrePlan) {
            const matchingPlan = paymentResp.data.plans.find(plan => plan.pid === userResponse.data.futrePlan.planId);  
            userResponse.data.futrePlan.title =matchingPlan.title;
            setUserFuturePlan(userResponse.data.futrePlan)
          } else {
            setUserFuturePlan(false)
          }
         
      
         } catch (error) {
           console.error('Error fetching data:', error);
         }
       };
   
       fetchData(); // Call the fetchData function when the component mounts
     }, []); 

     const updateAccount = async () => {
      
      let userId=localStorage.getItem("userId")
        if(userId) {
         const formData = new FormData();
         formData.append("userId", userId);
   
         const combinedName = `${firstName} ${lastName}`.trim();
         const originalCombinedName = `${originalValues.firstName} ${originalValues.lastName}`.trim();
         if (combinedName !== originalCombinedName) {
           formData.append("fullName", combinedName);
         }
   
         // Check if userName has changed
         if (userName !== originalValues.userName) {
           formData.append("userName", userName);
           if(!isUsernameUnique) {
            return true;
           }
         }
   
      const headers={
        "Content-Type": "application/json",
        }
        console.log(firstName,lastName,userName)
        setOriginalValues({
         firstName,
         lastName,
         userName,
       });
       const response = await callAPI(apiUrls.UPDATEACCOUNT, {}, "POST",formData,{},headers);
       if(response.data.id) {
        setUpdatedUserAccount(true)
       }
       setIsUsernameExists(true)
        }
    };

    return (
        <div class="dashContent">
          <h1>Account Settings</h1>
          <div class="dForm mt-32">
            <div class="wrapper">
               <section class="infBox">
                  <h3>Personal info
                     <span>Update your photo and personal details.</span>
                     {updatedUserAccount && (
                            <p style={{ color: '#32de84', marginTop: '8px' }}>Your account data is saved.</p>
                      )}
                  </h3>
                  
                   <div class="dbBox mt-12">
                     <div class="row gap24">
                        <div class="col flex flexColum w100">
                           <label class="mb-8">First name</label>
                           <input type="text" value={firstName}  name="" placeholder="First Name" class="w100" onChange={handleFirstNameChange}/>
                        </div>
                        <div class="col flex flexColum w100">
                           <label class="mb-8">Last name</label>
                           <input type="text" value={lastName} name="" placeholder="Last Name" class="w100" onChange={handleLastNameChange}/>
                        </div>
                     </div>
                     {/* <div class="row">
                        <div class="col flex flexColum w100">
                           <label class="mb-8">Username</label>
                           <div class="inputTypeT">
                              <label>
                                 <span>deck.work/</span>
                              </label>
                              <input type="text" name="" value={userName} class="w100"     style={{ color: isUsernameExists ? '#999999' : 'white'  }} onChange={handleUsernameChange}   disabled={isUsernameExists}  />
                           </div>
                           {userName && !isUsernameExists && !isUsernameUnique && (
                            <p style={{ color: 'red', marginTop: '8px' }}>{userName} is not unique. Please choose a different one.</p>
                            )}
                            {userName && !isUsernameExists && isUsernameUnique && (
                            <p style={{ color: '#32de84', marginTop: '8px' }}>{userName} is available.</p>
                            )}
                         </div>
                     </div> */}
                      <div class="row">
                        <div class="col flex flexColum w100">
                           <label class="mb-8">Email</label>
                           <div >
                              
                                 <h6>{displayEmailId}</h6>
                              
                           </div>
                    
                         </div>
                     </div>

                      <div class="actionButtons jcflexend w100">
                        <button class="btn PrimaryOutline disabled">Cancel</button>
                         <button className={`btn Primary ${isChangesMade ? '' : 'disabled'}`} onClick={updateAccount}>Save Changes</button>
                      </div>
                   </div>

                </section>
                { userFuturePlan && 
                <section class="infBox viewPlan">
                    <h3>
                     Subscription changes pending
                  </h3>
                  <div class="dbBox mt-12">
                  <div class="row jcSpacebetween gap24">
                    <p><span>
                  {`You will be moved to the ${userFuturePlan.title} plan on ${formatDateAsTh(userFuturePlan.planStart)}`}
                  </span></p>
                  </div>
                  </div>
                </section>
                }
                <section class="infBox viewPlan">
                  <h3>Your Plan
                     <span>Review your current plan</span>
                  </h3>
                   <div class="dbBox mt-12">
                     <div class="row jcSpacebetween gap24">
                         <div class="col">
                            <label>Current Plan</label>
                         </div>
                         { userPlan ? ( <div class="col mrAuto">
                           <p><span>{userPlan.title}</span></p>
                           <p><span>{userPlan.priceLabel}</span></p>
                           {displayLabel.map((item, index) => (
  <p key={index}>
    <span>
      {item.label}: {item.actual}/{item.restrict === -1 ? "Unlimited" : item.restrict}
    </span>
  </p>
))}
                         </div>
                         ):(
                           <div class="col mrAuto">
                            { isTrialPlan ? (
                              <span>"Free Plan - (Your 14 day trial ends on {trialExpiryDate})" </span>
                           
                            ):(
                              <span>You don't have an active plan. <a href="javascript:void(0);" class=" linkTxt" onClick={() => setActiveNavItem('Payment')}>Upgrade now</a>. </span>   
                            )}
                           </div>
                         )}
                         <div class="col">
                            <a href="javascript:void(0);" class="btn PrimaryOutline" onClick={() => setActiveNavItem('Payment')}>Upgrade Plan</a>
                         </div>
                      </div>
                   </div>
                </section>
                <p>If you’d like to remove your subscription, <a href="https://blog.deck.work/delete-account" class="linkTxt" target="new">please write to us.</a></p>
               
                {/* <section class="infBox">
                  <h3>Delete Account</h3>
                   <div class="dbBox mt-12">
                      <div class="row jcSpacebetween">
                         <p>If you’d like to delete your account, please write to us.</p>
                         <a href="https://blog.deck.work/delete-account" class="btn PrimaryOutline" target="new">Contact us</a>
                      </div>
                   </div>
                </section> */}
        
             </div>
          </div>
      </div>

    );
};

export default AccountSetting;